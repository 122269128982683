<template>
    <div class="dashboard">
        <div class="row margin-0">
            <div class="col-md-12 pad-15">
                <div class="parent margin-bottom-5">
                    <div class="parent-body">
                        <div class="row margin-0 margin-bottom-10">
                            <div class="col-12 pad-0 pad-top-5">
                                <h4>Reports</h4>
                            </div>
                            <div class="col-2 pad-0">
                                <div class="form-group">
                                    <label for="">Report</label>
                                    <select
                                        name=""
                                        id=""
                                        v-model="filter.report"
                                        class="form-control form-control-sm"
                                    >
                                        <option :value="null">Select Report</option>
                                        <option value="GST_REPORT">GST Report</option>
                                        <option value="COINS_REPORT">Coins Report</option>
                                        <!-- <option value="EXPORT_DOMESTIC">Export Vs Domestic Report</option> -->
                                        <!-- <option value="DISCOUNT_EXTENDED">Discount Extended Report</option> -->
                                    </select>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="">Range</label>
                                    <select
                                        name=""
                                        id=""
                                        v-model="filter.range"
                                        class="form-control form-control-sm"
                                    >
                                        <option :value="null">Select Range</option>
                                        <!-- <option value="DAY">Today</option> -->
                                        <option value="WEEK">Current Week</option>
                                        <option value="MONTH">Current Month</option>
                                        <option value="QUARTER">Last 6 Months</option>
                                        <option value="YEAR">Last 12 Months</option>
                                        <option value="CUSTOM">Custom</option>
                                        <!-- <option value="FINANCIAL_YEAR">Financial Year</option> -->
                                    </select>
                                </div>
                            </div>
                            <template v-if="filter.range && filter.range==='CUSTOM'">
                                <div class="col-2 pad-left-0">
                                    <div class="form-group">
                                        <label for="">Start Date</label>
                                        <input type="date" v-model="filter.start" placeholder="Start Date" class="form-control form-control-sm">
                                    </div>
                                </div>
                                <div class="col-2 pad-left-0">
                                    <div class="form-group">
                                        <label for="">End Date</label>
                                        <input type="date" v-model="filter.end" placeholder="End Date" class="form-control form-control-sm">
                                    </div>
                                </div>
                            </template>
                            <div class="col pad-left-0"><br>
                                <button class="btn btn-success btn-sm margin-right-10" @click="getStats();stats=null;">Search</button>
                                <button class="btn btn-danger btn-sm" @click="getStats();stats=null;">Clear</button>
                            </div>
                            <div class="col-12 pad-0 pad-top-5">
                                <div class="row margin-0">
                                    <div class="col-12 pad-0" v-if="filter.report==='GST_REPORT'&& stats">
                                        <table class="table sai-table">
                                            <thead>
                                                <tr class="t-head">
                                                    <th>Range</th>
                                                    <th>Currency</th>
                                                    <th>Total Sale</th>
                                                    <th>Taxes &amp; Deductables</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(item, idx) in Object.keys(stats)" :key="idx">
                                                <template v-for="(item1, idx1) in Object.keys(stats[item])" :key="idx1">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {{idx1===0?item:''}}
                                                            </td>
                                                            <td>
                                                                {{item1}}
                                                            </td>
                                                            <td>
                                                                {{stats[item][item1].total || 0}}
                                                            </td>
                                                            <td>
                                                                <template v-for="(item2, idx2) in Object.keys(stats[item][item1].taxes)" :key="idx2">
                                                                    <span>- {{itme2==='deductable'?'Total':item2}}: {{stats[item][item1].taxes[item2]}}</span><br>
                                                                </template>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </template>
                                        </table>
                                    </div>
                                    <div class="col-12 pad-0" v-if="filter.report==='COINS_REPORT'&& stats">
                                        <table class="table sai-table">
                                            <thead>
                                                <tr class="t-head">
                                                    <th>Range</th>
                                                    <th>Currency</th>
                                                    <th>Total Sale</th>
                                                    <th>Coins</th>
                                                    <th>Free Coins</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(item, idx) in Object.keys(stats)" :key="idx">
                                                <template v-for="(item1, idx1) in Object.keys(stats[item])" :key="idx1">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {{idx1===0?item:''}}
                                                            </td>
                                                            <td>
                                                                {{item1}}
                                                            </td>
                                                            <td>
                                                                {{stats[item][item1].total || 0}}
                                                            </td>
                                                            <td>
                                                                {{stats[item][item1].total_coins || 0}}
                                                            </td>
                                                            <td>
                                                                {{stats[item][item1].bonus_coins || 0}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
        stats: null,
        filter: {
            report: null,
            range: 'MONTH',
            start: moment().format('DD-MM-YYYY'),
            end: moment().format('DD-MM-YYYY'),
        }
    };
  },
  created() {
      this.getStats()
  },
  methods: {
    resetAll() {
        this.stats = null;
        this.filter = {
            report: null,
            range: 'MONTH',
            start: moment().format('DD-MM-YYYY'),
            end: moment().format('DD-MM-YYYY'),
        }
    },
      getStats() {
          this.$cs.admin.create({
            data: this.filter
          })
          .then(res => {
              this.stats = res.data
          })
      },
  }
}
</script>
<style scoped>
</style>
